<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import * as cloneDeep from "lodash.clonedeep";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import * as moment from "moment";
export const CHAIPAY_APP_URL = process.env.VUE_APP_CHAIPAY_FRONTEND_URL;
import FilterBtn from '../../../components/filter-btn.vue';
import { paymentMethods } from "@/state/helpers";
import {
  FETCH_SETTLEMENTS_SUMMARY_RESULTS,
  FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY
} from "@/state/actions.type";
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
    Multiselect,
    DateRangePicker,
    FilterBtn
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 200);
    return {
      pickerDates: {
        startDate,
        endDate
      },
      appliedFilter: 'month',
      appliedtype: 'amount',
      appliedAnalytics:'feeMismatch',
      selectedChannel:"",
      selectedExpandBy:"",
      expandByObj:{key:'',value:'Expand By'},
      expandBy:[{key:'',value:'Expand By'}, {key:'currency',value:'Country'},{key:'method',value:'Payment Method'},{key:'channel',value:'Payment channel'}],
      paymentLinksData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      mor_filter: "",
      filterOn: [],
      sortBy: "created_at",
      sortDesc: true,
      fields: [
        { key: "date_month", sortable: true, label: "Months", thClass: "",tdClass: "" },
        { key: "date_week", sortable: true, label: "Weeks", thClass: "d-none",tdClass: "d-none" },
        { key: "country", sortable: true, label: "Country", thClass: "",tdClass: "" },
        { key: "payment_channel", sortable: true, label: "Payment Channel", thClass: "",tdClass: "" },
        { key: "payment_method", sortable: true, label: "Payment Method", thClass: "",tdClass: "" },
        { key: "total_txn_amount", sortable: true, label: "Total Txn Amount" },
        { key: "txn_fee", sortable: true, label: "Txn fee" },
        { key: "wht", sortable: true, label: "WHT" },
        { key: "total_settlement_amount", sortable: true, label: "Total Settlement Amount"},
        { key: "pending_amount", sortable: true, label: "Pending Amount"},
        { key: "transfer_fee", sortable: true, label: "Transfer Fee"},
        { key: "remitted_amount", sortable: true, label: "Remitted Amount"},
        { key: "net_remitted_amount", sortable: true, label: "Net Amount Transferred"}
      ],
      isFilterApplied: false,
      showFilterSection:false,
      filter:{
        currency:[],
        method:[],
        channel:[]
      }
    };
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY");
    }
  },

  created() {
      this.updateColumns();
      var from_date = this.pickerDates.startDate
      var to_date = this.pickerDates.endDate
      this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
        pageIndex: 1,
        pagesize: 10,
        from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
        to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
        source:this.selectedChannel,
        frequency:this.appliedFilter,
        recon_type:"reconciled",
        filter:this.filter
      });
      this.paymentLinksData = cloneDeep(this.paymentLinksDataFromStore);
  },

  computed: {
    paymentLinksDataFromStore() {
      return this.$store.state.settlements.Summarycontent;
    },

    // paymentChannelList() {
    //   return this.$store.state.reconciliation.paymentChannelList;
    // },

    CountryData() {
      return this.$store.state.auth.currentUser.country;
    },

    isFetchingTransactions() {
      return this.$store.state.settlements.isFetchingData;
    },
    rows() {
      return this.$store.state.settlements.total_elements;
    },

    items() {
      return [
        {
          text: "Remittance",
          active: true,
        },
         {
          text: "Settlements",
          active: true,
        },
      ];
    },
    title() {
      return "Settlements";
    },
  },
  watch: {
    perPage: function (val) {
      var from_date = this.pickerDates.startDate
      var to_date = this.pickerDates.endDate
      this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
        pageIndex: this.currentPage,
        pagesize: val,
        from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
        to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
        source:this.selectedChannel,
        frequency:this.appliedFilter,
        recon_type:"reconciled",
        filter:this.filter
      });
    },

    currentPage: function (val) {
       var from_date = this.pickerDates.startDate
      var to_date = this.pickerDates.endDate
      this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
        pageIndex: val,
        pagesize: this.perPage,
        from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
        to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
        source:this.selectedChannel,
        frequency:this.appliedFilter,
        recon_type:"reconciled",
        filter:this.filter
      });
    },

    paymentLinksDataFromStore() {
      this.paymentLinksData = cloneDeep(this.paymentLinksDataFromStore);
    },
    selectedExpandBy(){
      var from_date = this.pickerDates.startDate
      var to_date = this.pickerDates.endDate

      if(this.selectedExpandBy){
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          field:this.selectedExpandBy,
          frequency:this.appliedFilter,
          recon_type:"reconciled",
          filter:this.filter
        });
      }else{
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          source:this.selectedChannel,
          frequency:this.appliedFilter,
          recon_type:"reconciled",
          filter:this.filter
        });
      }
      this.updateColumns();
    },
    expandByObj(){
      if(this.expandByObj){
        if(Object.keys(this.expandByObj).length > 0){
          this.selectedExpandBy = this.expandByObj.key
        }
      }else{
        this.selectedExpandBy = ""
      }
    }
  },

  mounted() {
    // Set the initial number of items
    this.paymentLinksData = cloneDeep(this.paymentLinksDataFromStore);
    this.totalRows = this.paymentLinksData.length;
  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
  },

  methods: {
    ...paymentMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    sortByDate(a, b, key) {
      if (key == "created_at") {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1 - d2;
      }
    },

    filterTransactionByMor() {
      var from_date = this.pickerDates.startDate
      var to_date = this.pickerDates.endDate
      if (this.mor_filter == "") {
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          source:this.selectedChannel,
          frequency:this.appliedFilter,
          recon_type:"reconciled",
          filter:this.filter
        });
      } else {
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          source:this.selectedChannel,
          frequency:this.appliedFilter,
          recon_type:"reconciled",
          filter:this.filter
        });
      }
    },

    updateFilter(filter) {
      this.appliedFilter = filter;
      // var from_date = this.pickerDates.startDate
      // var to_date = this.pickerDates.endDate
      // this.$store.dispatch(`settlements/${FETCH_RECONCILIATION_SUMMARY_RESULTS}`, {
      //   pageIndex: 1,
      //   pagesize: 10,
      //   from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
      //   to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
      //   source:this.selectedChannel,
      //   frequency:this.appliedFilter,
      //   recon_type:"reconciled"
      // });
    },
    updateColumns() {
      this.fields[2].thClass = "d-none"
      this.fields[2].tdClass = "d-none" 
      this.fields[3].thClass = "d-none"
      this.fields[3].tdClass = "d-none" 
      this.fields[4].thClass = "d-none"
      this.fields[4].tdClass = "d-none"
      this.fields[0].thClass = "d-none"
      this.fields[0].tdClass = "d-none"
      this.fields[1].thClass = "d-none"
      this.fields[1].tdClass = "d-none" 
      if(this.appliedFilter == "month"){
        this.fields[0].thClass = ""
        this.fields[0].tdClass = ""
      }else if(this.appliedFilter == "week"){
        this.fields[1].thClass = ""
        this.fields[1].tdClass = "" 
      }

      if(this.selectedExpandBy == "currency"){
        this.fields[2].thClass = ""
        this.fields[2].tdClass = "" 
      }else if(this.selectedExpandBy == "method"){
        this.fields[4].thClass = ""
        this.fields[4].tdClass = "" 
      }else if(this.selectedExpandBy == "channel"){
        this.fields[3].thClass = ""
        this.fields[3].tdClass = "" 
      }
    },
    updateAnalytics(filter) {
      this.appliedAnalytics = filter;
      this.updateColumns();
       
      // var from_date = this.pickerDates.startDate
      // var to_date = this.pickerDates.endDate
      // this.$store.dispatch(`settlements/${FETCH_RECONCILIATION_SUMMARY_RESULTS}`, {
      //   pageIndex: 1,
      //   pagesize: 10,
      //   from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
      //   to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
      //   source:this.selectedChannel,
      //   frequency:this.appliedFilter,
      //   recon_type:"reconciled"
      // });
    },
    fetchTransactionOnEmptyFilter() {
      if (this.mor_filter == "") {
        var from_date = this.pickerDates.startDate
        var to_date = this.pickerDates.endDate
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          source:this.selectedChannel,
          frequency:this.appliedFilter,
          recon_type:"reconciled",
          filter:this.filter
        });
      }
    },
    fetchFilters(){
        var from_date = this.pickerDates.startDate
        var to_date = this.pickerDates.endDate
        if(this.selectedExpandBy){
          this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY}`, {
            pageIndex: 1,
            pagesize: 10,
            from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
            to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
            field:this.selectedExpandBy,
            frequency:this.appliedFilter,
            recon_type:"reconciled",
            filter:this.filter
          }).then(() => {
            this.updateColumns();
          })
          .catch((error) => {
            // error
            console.log("error", error);
          });
        }else{
          this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
            pageIndex: 1,
            pagesize: 10,
            from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
            to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
            source:this.selectedChannel,
            frequency:this.appliedFilter,
            recon_type:"reconciled",
            filter:this.filter
          }).then(() => {
            this.updateColumns();
          })
          .catch((error) => {
            // error
            console.log("error", error);
          });
        }
    },
     convertCurrencyToCountry(val){
      if(val == "VND"){
          return "Vietnam"
      }else if(val== "THB"){
        return "Thailand"
      }else if(val== "SGD"){
        return "Singapore"
      }else if(val== "IDR"){
        return "Indonesia"
      }else if(val== "MYR"){
        return "Malaysia"
      }else if(val== "TWD"){
        return "Taiwan"
      }else if(val== "PHP"){
        return "Philippines"
      }else {
        return val
      }
    },
    openFilter(){
      this.showFilterSection = !this.showFilterSection
    },
    saveFilter(){
        var from_date = this.pickerDates.startDate
        var to_date = this.pickerDates.endDate
        if(this.selectedExpandBy){
          this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY}`, {
            pageIndex: 1,
            pagesize: 10,
            from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
            to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
            field:this.selectedExpandBy,
            frequency:this.appliedFilter,
            recon_type:"reconciled",
            filter:this.filter
          }).then(() => {
            this.updateColumns();
          })
          .catch((error) => {
            // error
            console.log("error", error);
          });
        }else{
          this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
            pageIndex: 1,
            pagesize: 10,
            from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
            to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
            source:this.selectedChannel,
            frequency:this.appliedFilter,
            recon_type:"reconciled",
            filter:this.filter
          }).then(() => {
            this.updateColumns();
          })
          .catch((error) => {
            // error
            console.log("error", error);
          });
        }
      this.showFilterSection = false;
      if(this.filter.method.length > 0 || this.filter.channel.length > 0 || this.filter.currency.length > 0) {
        this.isFilterApplied = true;
      } else {
        this.isFilterApplied = false;
      }
    },
    clearFilter(){
      this.filter = {
        currency:[],
        method:[],
        channel:[]
      }
        var from_date = this.pickerDates.startDate
        var to_date = this.pickerDates.endDate
        if(this.selectedExpandBy){
          this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY}`, {
            pageIndex: 1,
            pagesize: 10,
            from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
            to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
            field:this.selectedExpandBy,
            frequency:this.appliedFilter,
            recon_type:"reconciled",
            filter:this.filter
          }).then(() => {
            this.updateColumns();
          })
          .catch((error) => {
            // error
            console.log("error", error);
          });
        }else{
          this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
            pageIndex: 1,
            pagesize: 10,
            from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
            to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
            source:this.selectedChannel,
            frequency:this.appliedFilter,
            recon_type:"reconciled",
            filter:this.filter
          }).then(() => {
            this.updateColumns();
          })
          .catch((error) => {
            // error
            console.log("error", error);
          });
        }
      this.showFilterSection = false;
      this.isFilterApplied = false;
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"  :items="items" />
    <div class="row">
      <div class="col-lg-12">
       <!-- <div class="btn-group" style="margin-left: 15px; vertical-align: top;">
          <button type="button" id="feeMismatch" class="btn btn-sm" @click="updateAnalytics('feeMismatch')" :class="appliedAnalytics==='feeMismatch' ? 'btn-primary': 'btn-outline-primary'">Fee Mismatch</button>
          <button type="button" id="reconSummary" class="btn btn-sm" @click="updateAnalytics('reconSummary')" :class="appliedAnalytics==='reconSummary' ? 'btn-primary': 'btn-outline-primary'">Recon Summary</button>
        </div> -->
      </div>
      <div class="col-lg-12">
            <div class="row pb-2">
              <!-- <div class="col-sm-12 col-lg-3 col-md-3 d-inline-block">
              </div> -->
              <div class="check-mobile col-sx-5 col-sm-12 col-lg-7 col-md-7">
                <date-range-picker class="datepicker-custom float-left" style="margin-right: 15px;" :autoApply="true" :showDropdowns="true" :ranges="false" v-model="pickerDates">
                  <template
                    v-slot:input=""
                    style="min-width: 350px;"
                  >{{ pickerDates.startDate | date }} - {{ pickerDates.endDate | date }}</template>
                </date-range-picker>
                <div class="btn-group float-left">
                  <button type="button" id="weeks" class="btn btn-sm" @click="updateFilter('week')" :class="appliedFilter==='week' ? 'btn-primary': 'btn-outline-primary'">{{ $t('views.dashboard.week') }}</button>
                  <button type="button" id="months" class="btn btn-sm" @click="updateFilter('month')" :class="appliedFilter==='month' ? 'btn-primary': 'btn-outline-primary'">{{ $t('views.dashboard.month') }}</button>
                </div>
                <div class="btn-group float-left" style="margin-left: 15px;">
                  <b-button variant="primary" class="btn btn-block btn-sm" style="padding-left: 20px;padding-right: 20px;" @click="fetchFilters" >Apply</b-button>
                </div>
              </div>
              <div class="col-sm-12 col-lg-5 col-md-5 d-flex">
                <multiselect
                  v-model="expandByObj"
                  placeholder="Expand By"
                  :searchable="true"
                  :options="expandBy"
                  track-by="value"
                  label="value"
                  :multiple="false"
                  class="custom-multiselect cursor-pointer sm-multiselect"
                  :taggable="true"
                ></multiselect>
                  <div class="ml-3 d-md-inline-block">
                  <!-- <b-form-select v-model="currentSelectedFilter" style="display: inline-block;color: #252B3A;border-color: #252B3A;" size="sm" :options="filterOptions"></b-form-select>&nbsp; -->
                    <div style="width: max-content; height:35px;" class="filter_button" @click="openFilter()" ><FilterBtn :isActive="isFilterApplied" /></div>
                    <div v-if="showFilterSection" class="filter-section">
                      <div class="bottom-section float-right">
                          <div @click="openFilter()" class="mb-2"><FilterBtn :isActive="isFilterApplied" /></div>
                      </div>
                      <div class="my-3 h-50" style="margin-left: 4%; border-bottom: 1px solid #F0F0F0; padding-bottom: 20px;">
                          <button @click="saveFilter()" class="btn btn-primary btn-sm">
                            Apply
                          </button>
                          <button @click="clearFilter()" style="padding-left: 20px;" class="mr-2 btn btn-sm">Clear Filter</button>
                      </div>
                      <div class="top-section">
                          <div class="filter_channel">
                            <div class="filter_heading" style="padding-left: 20px;">COUNTRY</div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_vietnam" value="VND" v-model="filter.currency">
                                <label for="filter_vietnam">Vietnam (VND)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_thailand" value="THB" v-model="filter.currency">
                                <label for="filter_thailand">Thailand (THB)</label>
                              </div>
                              <!-- <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_singapore" value="SGD" v-model="filter.currency">
                                <label for="filter_singapore">Singapore (SGD)</label>
                              </div> -->
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_indonesia" value="IDR" v-model="filter.currency">
                                <label for="filter_indonesia">Indonesia (IDR)</label>
                              </div>
                              <!-- <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_malaysia" value="MYR" v-model="filter.currency">
                                <label for="filter_malaysia">Malaysia (MYR)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_philippines" value="PHP" v-model="filter.currency">
                                <label for="filter_philippines">Philippines (PHP)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_taiwan" value="TWD" v-model="filter.currency">
                                <label for="filter_taiwan">Taiwan (TWD)</label>
                              </div> -->
                          </div>
                          <div class="filter_method"> 
                              <div class="filter_heading" style="padding-left: 20px;">PAYMENT METHODS</div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_wallet" value="WALLET" v-model="filter.method">
                                <label for="filter_wallet">Wallet</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_CARD" value="CARD" v-model="filter.method">
                                <label for="filter_CARD">Card</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_INSTALLMENT" value="INSTALLMENT" v-model="filter.method">
                                <label for="filter_INSTALLMENT">INSTALLMENT</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_BANK_TRANSFER" value="BANK_TRANSFER" v-model="filter.method">
                                <label for="filter_BANK_TRANSFER">Bank Transfer</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_BNPL" value="BNPL" v-model="filter.method">
                                <label for="filter_BNPL">BNPL</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_NET_BANKING" value="NET_BANKING" v-model="filter.method">
                                <label for="filter_NET_BANKING">Net Banking</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_QR_CODE" value="QR_CODE" v-model="filter.method">
                                <label for="filter_QR_CODE">QR Code</label>
                              </div>
                            </div>
                          <div class="filter_channels"> 
                              <div class="filter_heading" style="padding-left: 20px;">PAYMENT CHANNELS</div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_ESPAY" value="ESPAY" v-model="filter.channel">
                                <label for="filter_ESPAY">ESPAY</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_BAOKIM" value="BAOKIM" v-model="filter.channel">
                                <label for="filter_BAOKIM">BAOKIM</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_9PAY" value="9PAY" v-model="filter.channel">
                                <label for="filter_9PAY">9PAY</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_MOMOPAY" value="MOMOPAY" v-model="filter.channel">
                                <label for="filter_MOMOPAY">MOMOPAY</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_GBPRIMEPAY" value="GBPRIMEPAY" v-model="filter.channel">
                                <label for="filter_GBPRIMEPAY">GBPRIMEPAY</label>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-9">
                <div id="tickets-table_length" class="dataTables_length">
                  <!-- <label style="font-size: 13px;" class="d-inline-flex align-items-center">
                    Show&nbsp;Entries
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      style="border: 1px solid #EAEAEA;"
                    ></b-form-select
                    >
                  </label> -->
                  <!-- <b-input-group class="d-inline-flex align-items-center input-group col-lg-5">
                    <b-form-input
                      type="search"
                      v-model="mor_filter"
                      @keyup="fetchTransactionOnEmptyFilter"
                      @keyup.enter="filterTransactionByMor"
                      class="form-control form-control-sm ml-2"
                      placeholder="Search"
                      style="border: 1px solid #EAEAEA;"
                    ></b-form-input>
                    <b-input-group-append>
                      <button
                        class="btn btn-primary btn-block btn-sm search-button"
                        @click="filterTransactionByMor"
                      >
                        {{ $t("views.payments.transactions.search") }}
                      </button>
                    </b-input-group-append>
                  </b-input-group> -->
                </div>
              </div>
            </div>
            <!-- <b-tabs nav-class="nav-tabs-custom mb-4"> -->
              <!-- Payment Channel tab -->
              <!-- <b-tab
                @click="updateAnalytics('feeMismatch')"
                title="Fee Mismatch"
                title-link-class="p-3"
              >
              </b-tab>
              <b-tab
                @click="updateAnalytics('reconSummary')"
                title="Recon Summary"
                title-link-class="p-3"
              >
              </b-tab>
            </b-tabs> -->

            <div class="table-responsive text-center table-custom mt-3">
              <b-table
                class="table-centered"
                :items="paymentLinksData"
                :fields="fields"
                responsive="sm"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-compare="sortByDate"
                :bordered="true"
                :small="true"
                :filter-included-fields="filterOn"
                sticky-header="500px"
                show-empty
                empty-text="There are no records to show"
                busy.sync="true"
                hover
              >
                <template v-slot:cell(country)="row">
                  <div>
                    {{convertCurrencyToCountry(row.value)}}
                  </div>
                </template>
                <!-- <template v-slot:cell(total_settlement_amount)="row">
                  <div>
                    {{row.value.toFixed(2)}}
                  </div>
                </template>
                <template v-slot:cell(pending_amount)="row">
                  <div >
                    {{row.value.toFixed(2)}}
                  </div>
                </template>
                <template v-slot:cell(total_txn_amount)="row">
                  <div >
                    {{row.value.toFixed(2)}}
                  </div>
                </template>
                <template v-slot:cell(txn_fee)="row">
                  <div >
                    {{row.value.toFixed(2)}}
                  </div>
                </template>
                <template v-slot:cell(wht)="row">
                  <div >
                    {{row.value.toFixed(2)}}
                  </div>
                </template>
                <template v-slot:cell(remitted_amount)="row">
                  <div >
                    {{row.value.toFixed(2)}}
                  </div>
                </template>
                <template v-slot:cell(transfer_fee)="row">
                  <div >
                    {{row.value.toFixed(2)}}
                  </div>
                </template> -->
                <template v-slot:cell(net_remitted_amount)="row">
                  <div >
                    <router-link v-if="selectedExpandBy == 'currency' || selectedExpandBy == 'channel' || !selectedExpandBy" :to="`/remittances/summary?date=${row.item.date_raw}&date_type=${appliedFilter}&type=${selectedExpandBy}&type_value=${row.item.country}`">
                      {{row.value.toFixed(2)}}
                    </router-link>
                    <div v-if="selectedExpandBy == 'method'">
                      {{row.value.toFixed(2)}}
                    </div>
                  </div>
                </template>
              </b-table>
              <Spinner v-if="isFetchingTransactions === true"></Spinner>
              <!-- <div style="text-align: left;"><span style="display: inline-block;font-size: 22px;vertical-align: top;">*</span> All values in USD (except "Net Amount Transferred")</div> -->
            </div>
            <div class="row">
              <div class="col">
                <!-- <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                  style="padding-bottom: 20px;"
                > -->
                  <!-- <ul class="pagination pagination-rounded mb-0"> -->
                    <!-- pagination -->
                    <!-- <b-pagination
                      v-model="currentPage"
                      :current-page="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul> -->
                <!-- </div> -->
                <div class="clear-both"></div>
              </div>
            </div>
            <!-- </b-tab> -->
            <!-- </b-tabs> -->
      </div>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
  background: #fff;
  border: none;
  border-bottom: 1px solid #eff2f7;
  padding-top: 12px;
  padding-bottom: 12px;    
  font-size: 13px;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep .filter_button button{
  height: 100%;
}
::v-deep .filter_button button:focus,.filter_button button:active {
  outline: none !important;
  box-shadow: none;
}

.search-button{
  background: #EAEAEA;
  border-color: #EAEAEA;
  color: #B3AFAF;
}
::v-deep .table-custom .table.b-table > thead > tr > .table-b-table-default{
  font-size:12px;
  border-right: 1px solid #D9D9D9;
  background-color: #F5F5F5;
}
::v-deep  .custom-multiselect .multiselect__tags{
  border: 0.5px solid #00246414 !important;
}
::v-deep .main-content {
    overflow: visible;
}
::v-deep  .datepicker-custom .reportrange-text{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
} 
input#file-upload-button{
  cursor: pointer;
}
 .filter-section{
      box-shadow: 0px 3px 10px #0000001a;
      background: #fff;
      position: absolute;
      top: 0px;
      z-index: 9;
      width: 380px;
      right:0px;
  }
  .top-section{
      border-bottom: 1px solid #F0F0F0;
      padding: 15px;
      padding-left: 5px;
      padding-top: 0px;
  }
  .clear_filter{
    color: #BFBFBF;
    display: inline-block;
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
  }
  .bottom-section{
      padding: 15px;
      padding-left: 35px;
  }
  .filter_heading{
      color: #BFBFBF;
      padding-bottom: 15px;
      font-size: 13px;
      padding-top:10px ;
  }
  .checkbox-group{
    position: relative;
    display: inline-block;
  }
  .checkbox-group input{
    display: none;
  }
  .checkbox-group  label {
    cursor: pointer;
    font-size: 13px;
  }
  .checkbox-group  label:before {
    content:'';
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #DAD9D9;
    border-radius: 4px;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    bottom:1px;
  }
  .checkbox-group input:checked+label:before {
    background-color: #252B3A;
    border: 1px solid #252B3A;
  }

  .checkbox-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 25px;
    width: 5px;
    height: 9px;
    border: 1px solid #fff;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }
  .filter_channel,.filter_method{
    display: block;
    border-bottom: 1px solid #F0F0F0;
  }
 @media (max-width: 767px) {
  .check-mobile{
    margin-top: 20px;
  }
}
</style>